
/* file to manage context in mobile app, because of an issue with contextSelector events */

import { ref, reactive, computed } from "vue";

import myContext from "o365.modules.Context.ts";
import EventEmitter from "o365.modules.EventEmitter.ts";

export const contextHandler = new EventEmitter();

export const context = reactive(myContext);

export const contextName = ref(myContext.orgUnit);

export async function setContext(...args) {
    let result;
    try {
        // there is an issue with setContext, applying it twice is a workaround
        result = await context.setContext(...args);
        result = await context.setContext(...args);
        contextName.value = result.name;
    }
    catch (err) {
        console.warn("ERROR", err);
    }
    contextHandler.emit("Change");
    return result;
}

contextHandler.on("Change", () => console.log("sitesetup: context changed"));
